import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import axios from "@/axios";
import qs from "querystring";
import "@/utils/rem";
import "@/utils/extra";
import { handleImg } from "@/utils/utils";
import "@/assets/fonts/fonts.css";
Vue.config.productionTip = false;
import {
  Toast,
  PullRefresh,
  AddressEdit,
  Calendar,
  Overlay,
  SwipeCell,
  Switch,
} from "vant";
import { baseUnit } from "../public/config/config.js";

Vue.use(Toast);
Vue.use(Switch);
Vue.use(SwipeCell);
Vue.use(Overlay);
Vue.use(AddressEdit);
Vue.use(PullRefresh);
Vue.use(Calendar);
import {
  vList,
  vRadioGroup,
  vDateTimePicker,
  vPicker,
  vInput,
  vPicList,
  vUpload,
  vCalendar,
  vDialog,
  vEmpty,
  vPopup,
  vStepper,
  vStep,
  vRefresh,
  vSearch,
  vSign,
  vDropDownMenu,
  vOpen,
  vCommentList,
  vH5Mtml,
} from "@/components/control/index";
Vue.component("v-h5Mtml", vH5Mtml);
Vue.component("v-open", vOpen);
Vue.component("v-stepper", vStepper);
Vue.component("v-dropDownMenu", vDropDownMenu);
Vue.component("v-search", vSearch);
Vue.component("v-step", vStep);
Vue.component("v-popup", vPopup);
Vue.component("v-empty", vEmpty);
Vue.component("v-list", vList);
Vue.component("v-picList", vPicList);
Vue.component("v-radio-group", vRadioGroup);
Vue.component("v-dateTimePicker", vDateTimePicker);
Vue.component("v-picker", vPicker);
Vue.component("v-input", vInput);
Vue.component("v-upload", vUpload);
Vue.component("v-sign", vSign);
// Vue.component("v-input", vInput);
Vue.component("v-refresh", vRefresh);
Vue.component("v-calendar", vCalendar);
Vue.component("v-dialog", vDialog);
Vue.component("v-commentList", vCommentList);
Vue.prototype.$qs = qs;
Vue.prototype.$baseUnit = baseUnit;
Vue.prototype.$axios = axios;
Vue.prototype.$toast = Toast;
Vue.prototype.$handleImg = handleImg;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
