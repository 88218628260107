const routes = [
  //  空调
  {
    path: "/airConditioner/airConditionerSwitch",
    name: "airConditionerSwitch",
    meta: {
      title: "空调",
    },
    component: (resolve) =>
      require(["@/views/airConditioner/airConditionerSwitch.vue"], resolve),
  },
  //  设备
  {
    path: "/equipment/roomEquipment",
    name: "roomEquipment",
    meta: {
      title: "设备",
    },
    component: (resolve) =>
      require(["@/views/airConditioner/roomEquipment.vue"], resolve),
  },
  //  灯光
  {
    path: "/equipment/lightSwitch",
    name: "lightSwitch",
    meta: {
      title: "灯光",
    },
    component: (resolve) =>
      require(["@/views/airConditioner/lightSwitch.vue"], resolve),
  },

];

export default routes;
