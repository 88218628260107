const routes = [
  //  设备巡检
  {
    path: "/workOrder/inspectionTasks",
    name: "inspectionTasks",
    meta: {
      title: "设备巡检",
    },
    component: (resolve) =>
      require(["@/views/workOrder/inspectionTasks/index"], resolve),
  },
  //  巡检详情
  {
    path: "/workOrder/inspectionTasksDetail",
    name: "inspectionTasksDetail",
    meta: {
      title: "巡检详情",
    },
    component: (resolve) =>
      require([
        "@/views/workOrder/inspectionTasks/inspectionTasksDetail",
      ], resolve),
  },
  //  设备维保
  {
    path: "/workOrder/deviceMaintenance",
    name: "deviceMaintenance",
    meta: {
      title: "设备维保",
    },
    component: (resolve) =>
      require(["@/views/workOrder/deviceMaintenance/index"], resolve),
  },
  //  设备维保详情
  {
    path: "/workOrder/deviceMaintenanceDetail",
    name: "deviceMaintenanceDetail",
    meta: {
      title: "设备维保",
    },
    component: (resolve) =>
      require([
        "@/views/workOrder/deviceMaintenance/deviceMaintenanceDetail",
      ], resolve),
  },
  //  维修工单
  {
    path: "/workOrder/maintenanceWorkOrder",
    name: "maintenanceWorkOrder",
    meta: {
      title: "维修工单",
    },
    component: (resolve) =>
      require(["@/views/workOrder/maintenanceWorkOrder/index"], resolve),
  },
  //  维修工单-服务中详情
  {
    path: "/workOrder/workOrderServeDetail",
    name: "workOrderServeDetail",
    meta: {
      title: "维修工单",
    },
    component: (resolve) =>
      require([
        "@/views/workOrder/maintenanceWorkOrder/workOrderServeDetail",
      ], resolve),
  },
  // 维修上报
  {
    path: "/workOrder/maintenanceReport",
    name: "maintenanceReport",
    meta: {
      title: "维修工单",
    },
    component: (resolve) =>
      require(["@/views/workOrder/maintenanceReport/index.vue"], resolve),
  },
  // 维修上报-反馈填写
  {
    path: "/workOrder/maintenanceFeedback",
    name: "maintenanceFeedback",
    meta: {
      title: "内容填写",
    },
    component: (resolve) =>
      require([
        "@/views/workOrder/maintenanceReport/maintenanceFeedback.vue",
      ], resolve),
  },
  // 维修上报-房号选择
  {
    path: "/workOrder/maintenanceReportEditPlace",
    name: "maintenanceReportEditPlace",
    meta: {
      title: "房号选择",
    },
    component: (resolve) =>
      require([
        "@/views/workOrder/maintenanceReport/maintenanceReportEditPlace.vue",
      ], resolve),
  },
  // 维修上报-房号选择
  {
    path: "/workOrder/myMaintenanceReport",
    name: "myMaintenanceReport",
    meta: {
      title: "我的上报",
    },
    component: (resolve) =>
      require([
        "@/views/workOrder/maintenanceReport/myMaintenanceReport.vue",
      ], resolve),
  },
  // 维修上报-详情
  {
    path: "/workOrder/myMaintenanceDetail",
    name: "myMaintenanceDetail",
    meta: {
      title: "我的上报",
    },
    component: (resolve) =>
      require([
        "@/views/workOrder/maintenanceReport/myMaintenanceDetail.vue",
      ], resolve),
  },
  // 维修上报-详情
  {
    path: "/workOrder/workOrderConsumable",
    name: "workOrderConsumable",
    meta: {
      title: "维修耗材",
    },
    component: (resolve) =>
      require([
        "@/views/workOrder/maintenanceWorkOrder/workOrderConsumable.vue",
      ], resolve),
  },
  // 首页
  {
    path: "/workOrder/workbenchHomePage",
    name: "workbenchHomePage",
    meta: {
      title: "首页",
    },
    component: (resolve) =>
      require(["@/views/workOrder/workbenchHomePage/index.vue"], resolve),
  },
  // 设备信息
  {
    path: "/workOrder/equipmentInfo",
    name: "equipmentInfo",
    meta: {
      title: "设备信息",
    },
    component: (resolve) =>
      require([
        "@/views/workOrder/workbenchHomePage/equipmentInfo.vue",
      ], resolve),
  },
  // 工单详情
  {
    path: "/workOrder/workOrderDetails",
    name: "workOrderDetails",
    meta: {
      title: "工单详情",
    },
    component: (resolve) =>
      require([
        "@/views/workOrder/workbenchHomePage/workOrderDetails.vue",
      ], resolve),
  },
  // 我的业绩
  {
    path: "/workOrder/myPerformance",
    name: "myPerformance",
    meta: {
      title: "我的业绩",
    },
    component: (resolve) =>
      require([
        "@/views/workOrder/workbenchHomePage/myPerformance.vue",
      ], resolve),
  },
];

export default routes;
